<template>
  <div class="container-fluid">

    <h4 class="text-center my-4">Сводная ведомость</h4>

    <div class="container my-4">

      <div class="row mt-4">
        <label class="col-md-3 col-form-label">Курс</label>
        <div class="col-md-9">
          <select class="form-control form-select"
                  @input="changeStudyCourse($event)">
            <option selected value="0">Выберите курс</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
          </select>
        </div>
      </div>


      <div class="row mt-4">
        <label class="col-md-3 col-form-label">Уровень
          обучения</label>
        <div class="col-md-9">

          <div v-if="consolidatedStatement_form.statementInfos.study_course == 0">
            <p><strong style="color: red">Курс не выбран</strong></p>
          </div>
          <div v-else>
            <select class="form-control form-select"
                    @input="changeStudyLevel($event)">
              <option
                v-for="(item, index) in [{id: 0, name: 'Выберите уровень обучения'}, ...consolidatedStatement_form.studyLevel]"
                :value="item.id"
                :selected="item.id==consolidatedStatement_form.studyLevelId"
                :key="index">{{ item.name }}
              </option>
            </select>
          </div>


        </div>
      </div>

      <div class=" row mt-4">
        <label class="col-md-3 col-form-label">Образовательная программа</label>
        <div class="col-md-9">
          <div v-if="studyLevelId == 0">
            <b class="text-danger">Уровень обучения не выбран</b>
          </div>
          <div v-else>
            <select class="form-control form-select"
                    @input="changeStatement('education_program_id', $event)">
              <option v-for="(item, index) in [{education_speciality_name: 'Выберите ОП', language_name: 'язык', admission_year:'год' },
                {id: 0, education_speciality_name: 'Все', language_name: 'язык', admission_year:'год' }, ...consolidatedStatement_form.educationPrograms]"
                      :value="item.id"
                      :key="index">
                {{ item.education_speciality_code }} {{ item.education_speciality_name }} - {{item.language_name }} -
                {{ item.admission_year }}
              </option>
            </select>
          </div>

        </div>
      </div>


      <div class="row mt-4">
        <div class="col-md-3">
          <p>Учебный год</p>
        </div>
        <div class="col-md-9">
          <select class="form-control form-select"
                  @input="changeStatement( 'sp_academic_year_id', $event)">
            <option
              v-for="(item, index) in [{id: 0, name: 'Выберите учебный год'}, ...consolidatedStatement_form.academicYear]"
              :value="item.id"
              :key="index">{{ item.name }}
            </option>
          </select>
        </div>
      </div>


      <div class="row mt-4">
        <label class="col-md-3 col-form-label">Семестр</label>
        <div class="col-md-9">
          <select class="form-control form-select"
                  @input="changeStatement('semester', $event)">
            <option selected value="0">Выберите семестр</option>
            <option value="1">Осень</option>
            <option value="2">Весна</option>
          </select>
        </div>
      </div>


      <div class="row mt-4">
        <label class="col-md-3 col-form-label">Тип</label>
        <div class="col-md-9">
          <select class="form-control form-select"
                  @input="changeStatement('statement_type', $event)">
            <option selected>Выберите тип</option>
            <option value="vsk1">ВСК1</option>
            <option value="vsk2">ВСК2</option>
            <option value="exam">Экзамен</option>
            <option value="total">Итоговая</option>
            <option value="summer">Летник</option>
            <option value="all">Все</option>
          </select>
        </div>
      </div>


      <div class="my-4 text-center">
        <Button icon="pi pi-table" label="Сформировать" class="p-button-rounded"
                :loading="formationStatus"
                :disabled="+consolidatedStatement_form.statementInfos.semester===0"
                @click="formStatement"
        />
      </div>

    </div>



    <div class="my-4" v-if="Object.keys(consolidatedStatement_form.consolidatedStatementData).length">

      <div v-for="(consolidatedStatementGroups,key) in consolidatedStatement_form.consolidatedStatementData"
           :key="key">

        <div class="fw-bold mt-3">
          {{ consolidatedStatementGroups.group_name }}
        </div>

        <!-- table start -->
        <div class="table-responsive mt-2">
          <table class="table table-striped table-bordered border-dark small_font">

            <thead>
            <tr>
              <th scope="col" rowspan="2">№</th>
              <th scope="col" rowspan="2">ФИО</th>
              <th scope="col" rowspan="2">Штрихкод</th>
              <th scope="col" :colspan="checkTypeAll ? 4 : 1"
                  v-for="(consolidatedStatementDisciplines,disciplinesKey) in consolidatedStatementGroups.discipline"
                  :key="disciplinesKey">
                {{consolidatedStatementDisciplines.discipline_name}}
              </th>
            </tr>
            <tr>

              <template
                v-for="(consolidatedStatementDisciplines,disciplinesKey) in consolidatedStatementGroups.discipline"
                :key="disciplinesKey">
                <th scope="col"
                    v-if="consolidatedStatement_form.statementInfos.statement_type === 'vsk1' || checkTypeAll">ВСК1
                </th>
                <th scope="col"
                    v-if="consolidatedStatement_form.statementInfos.statement_type === 'vsk2' || checkTypeAll">ВСК2
                </th>
                <th scope="col"
                    v-if="consolidatedStatement_form.statementInfos.statement_type === 'exam' || checkTypeAll">Экзамен
                </th>
                <th scope="col"
                    v-if="consolidatedStatement_form.statementInfos.statement_type === 'total' || checkTypeAll || consolidatedStatement_form.statementInfos.statement_type === 'summer'">Итог
                </th>
              </template>

            </tr>
            </thead>
            <tbody>

            <tr v-for="(consolidatedStatementStudents,studentsKey, index) in consolidatedStatementGroups.students"
                :key="studentsKey">

              <th scope="row">{{ index + 1 }}</th>

              <td>{{consolidatedStatementStudents.student_fio_short}}</td>
              <td>{{ consolidatedStatementStudents.barcode }}</td>


              <template
                v-for="(consolidatedStatementDisciplines,disciplinesKey) in consolidatedStatementGroups.discipline"
                :key="disciplinesKey">

                <template v-if="consolidatedStatementStudents.rating[disciplinesKey]">
                  <td :class="{danger: consolidatedStatementStudents.rating[disciplinesKey]?.vsk1 < 50}"
                      v-if="consolidatedStatement_form.statementInfos.statement_type === 'vsk1' || checkTypeAll">
                    {{ consolidatedStatementStudents.rating[disciplinesKey]?.vsk1 }}
                  </td>
                  <td :class="{danger: consolidatedStatementStudents.rating[disciplinesKey]?.vsk2 < 50}"
                      v-if="consolidatedStatement_form.statementInfos.statement_type === 'vsk2' || checkTypeAll">
                    {{ consolidatedStatementStudents.rating[disciplinesKey]?.vsk2 }}
                  </td>
                  <td :class="{danger: consolidatedStatementStudents.rating[disciplinesKey]?.exam < 50}"
                      v-if="consolidatedStatement_form.statementInfos.statement_type === 'exam' || checkTypeAll">
                    {{ consolidatedStatementStudents.rating[disciplinesKey]?.exam }}
                  </td>
                  <td :class="{danger: consolidatedStatementStudents.rating[disciplinesKey]?.total < 50}"
                      v-if="consolidatedStatement_form.statementInfos.statement_type === 'total' || checkTypeAll || consolidatedStatement_form.statementInfos.statement_type === 'summer' ">
                    {{ consolidatedStatementStudents.rating[disciplinesKey]?.total }}
                  </td>
                </template>
                <template v-else>
                  <td :colspan="checkTypeAll?4:1" style="background-color: #8080FF;">нет в журнале</td>
                </template>

              </template>


            </tr>

            </tbody>
          </table>
          <!-- table end -->
        </div>


      </div>


    </div>


  </div>
</template>

<script>
  import {mapGetters, mapActions, mapMutations, mapState} from "vuex"


  export default {
    name: "ConsolidatedStatement",
    data() {
      return {
        filteredEducationPrograms: [],
        studyLevelId: 0,
        studyCourse: 0,
        formStatementBtnDisabled: false,
        formationStatus: false
      }
    },
    computed: {
      ...mapState('consolidatedStatement', ['consolidatedStatement_form']),
      checkTypeAll() {
        return this.consolidatedStatement_form.statementInfos.statement_type === 'all'
      }
    },

    methods: {
      ...mapActions('consolidatedStatement', ['GET_STUDY_LEVEL_NAMES', 'GET_EDUCATION_PROGRAM_NAMES', 'GET_SP_ACADEMIC_YEAR', 'GET_CONSOLIDATED_STATEMENT']),
      ...mapMutations('consolidatedStatement', ['SET_STATEMENT_DATA', 'SET_STUDY_COURSE']),

      changeStatement(property, e, val = 'value') {
        let value = e.target[val]
        this.SET_STATEMENT_DATA({property, value})
      },
      changeStudyLevel(e) {
        this.studyLevelId = e.target.value
        this.GET_EDUCATION_PROGRAM_NAMES(this.studyLevelId)
      },
      changeStudyCourse(e) {
        this.studyCourse = e.target.value
        this.SET_STUDY_COURSE(this.studyCourse)
      },
      async formStatement() {
        this.formationStatus = true
        await this.GET_CONSOLIDATED_STATEMENT()
        this.formationStatus = false
      }
    },

    async mounted() {
      await this.GET_SP_ACADEMIC_YEAR()
      await this.GET_STUDY_LEVEL_NAMES()
    }
  }
</script>

<style scoped>
  .small_font {
    font-size: 10px;
  }

  .danger {
    background-color: #FF8080;
  }
</style>